.footer-wrap {
    width: 100%;
    min-height: 80px;
    display: inline-block;
    justify-content: center;
    align-content: center;
    background: #025594;
    color: white;
}

    .footer-wrap .footer {
        display: block;
        position: relative;
        clear: both;
        background: #025594;
        color: white;
        width: 100%;
        text-align: center;
    }

    .footer-wrap a:hover {
        text-decoration: underline;
        color: #6b5e4b;
    }

        .footer-wrap .footer p {
            font-size: 1rem;
            padding-left: 13%;
            margin-bottom: 0;
        }

        .footer-wrap .footer ul {
            text-align: center;
        }

            .footer-wrap .footer ul li {
                list-style: none;
                cursor: pointer;
                transition: ease-in-out 0.3s;
                text-transform: none;
                padding: 10px 12px;
                display: inline-block;
            }

                .footer-wrap .footer ul li:hover {
                    background: transparent;
                }

        .footer-wrap .footer .grid-thirds {
            align-items: center;
            justify-content: center;
        }

        .footer-wrap .footer .legal-info {
            text-align: center;
        }

    .footer-wrap .footer-logo img {
        max-width: 350px;
        padding: 15px;
    }

    .footer-wrap .phone-icon,
    .footer-wrap .address-icon {
        color: rgb(218, 33, 39);
        padding-right: 3px;
    }

    .footer-wrap .social-icons {
        /*color: white;*/
    }

    .footer-wrap .copyright-info p,
    .footer-wrap .powered-by p {
        font-size: 20px;
    }

    .footer-wrap .footer ul {
        display: block;
    }

        .footer-wrap .footer ul li {
            padding: 0px 0px 3px 0px;
            float: left;
        }

    .footer-wrap .footer-social-icons {
        display: block;
    }

    .footer-wrap .footer-logo {
    }

    .footer-wrap .menu-items .direct-link {
        display: block;
    }

    .footer-wrap .contact-info {
        width: 30%;
    }

        .footer-wrap .contact-info .footer-phone,
        .footer-wrap .contact-info .footer-address {
            display: flex;
            font-size: 22px;
            float: left;
            color: darkslategrey;
        }

    .footer-wrap .footer div {
        margin: 0 auto;
        width: 100%;
        text-align: left;
    }

    .footer-wrap .site-info {
        background-color: rgb(77, 77, 77);
        font-size: 22px;
        color: white;
    }

    .footer-wrap .footer-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

        .footer-wrap .footer .footer-section-header {
            font-weight: bold;
            width: 100%;
            border-bottom: 2px solid darkslategrey;
            color: darkslategrey;
            padding-bottom: 0;
        }

        .footer-wrap .footer .footer-social-icons {
            padding: 0 50px;
        }

        .footer-wrap .footer .footer-menu-items {
            padding: 0 50px;
        }

        .footer-wrap .footer .contact-info {
            padding: 0 100px;
        }

        .footer-wrap .footer .menu-items-container .dropdown .dropdown-btn {
            padding: 12px 0px;
        }

        .footer-wrap .footer .menu-items-container .dropdown .dropdown-content {
            width: auto;
        }

    .footer-wrap li {
        font-size: 22px;
    }

        .footer-wrap li:hover {
            color: #0056b3 !important;
            background-color: transparent;
        }

    .footer-wrap .copyright-info {
        text-align: center;
    }

        .footer-wrap .copyright-info p {
            text-align: center;
            padding: 0;
            margin: 0;
        }

    .footer-wrap .powered-by p {
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .footer-wrap .super-footer-container {
        background-color: #eaf9ff;
        color: #095587;
        display: flex;
        padding: 0 10%;
    }

        .footer-wrap .super-footer-container .super-footer-header-wrapper {
            width: 40%;
        }

        .footer-wrap .super-footer-container .super-footer-header {
            margin: 25px auto;
            text-align: center;
            border-right: 4px solid #e37051;
        }

            .footer-wrap .super-footer-container .super-footer-header img {
                width: 140px;
            }

            .footer-wrap .super-footer-container .super-footer-header .header-text-container {
                padding-left: 5%;
                display: inline-block;
                vertical-align: middle;
            }

                .footer-wrap .super-footer-container .super-footer-header .header-text-container .super-text {
                    display: block;
                    font-size: 34px;
                    font-weight: bold;
                }

                .footer-wrap .super-footer-container .super-footer-header .header-text-container .sub-text {
                    font-size: 24px;
                    font-weight: bold;
                    float: left;
                    margin-top: -10px;
                }

        .footer-wrap .super-footer-container .super-footer-content {
            margin: 50px auto;
            display: grid;
            grid-template-columns: 2fr 3fr;
        }

            .footer-wrap .super-footer-container .super-footer-content .content-description-container {
                padding: 10px 40px;
                font-size: 18px;
            }

                .footer-wrap .super-footer-container .super-footer-content .content-description-container .content-description {
                    display: block;
                }

            .footer-wrap .super-footer-container .super-footer-content form {
                padding: 14px 0;
            }

                .footer-wrap .super-footer-container .super-footer-content form input {
                    padding: 12px;
                    width: 60%;
                }

                .footer-wrap .super-footer-container .super-footer-content form button {
                    padding: 14px;
                    background-color: #095587;
                    color: white;
                }

                .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper {
                    display: inline-block;
                    width: 30%;
                }

                    .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper input {
                        background-color: #095587;
                        color: white;
                    }

    .footer-wrap .footer {
        width: 100%;
        text-align: center;
        border-top: 10px solid #695f4c;
        padding: 1% 10% 3px 10%;
    }

    .footer-wrap .footer .footer-social-column {
        padding-left: 27%;
    }

        .footer-wrap .footer .footer-social-column .social-header {
            font-weight: bold; 
            margin-bottom: 1rem;
            font-size: 16pt;
        }

        .footer-wrap .footer .footer-social-column .social-icons {
            font-size: 14pt;
        }

@media only screen and (max-width: 1400px) {
    .footer-wrap {
        min-height: 125px;
    }

        .footer-wrap .footer .footer-menu-items {
            display: none;
        }


}

@media only screen and (max-width: 1000px) {
    .footer-wrap .super-footer-container .super-footer-header-wrapper {
        display: none;
    }

    .footer-wrap .super-footer-container .super-footer-content {
        margin: 0 auto;
        grid-template-columns: 1fr;
        width: 90%;
        text-align: center;
    }

        .footer-wrap .super-footer-container .super-footer-content form .submit-btn-wrapper input {
            width: auto;
        }

    .footer-wrap .footer-column-grid {
        grid-template-columns: 1fr;
    }

        .footer-wrap .footer-column-grid div {
            padding: 1%;
            text-align: center;
        }

            .footer-wrap .footer-column-grid div p {
                text-align: center !important;
                padding-left: 0;
            }

    .footer-wrap .footer-social-column {
        text-align: center;
        margin: auto;
    }

        .footer-wrap .footer-social-column .social-icons svg {
            /*margin-left: 17%;
            margin-right: 3%;*/
        }

    .footer-wrap .footer .footer-social-column {
        padding-left: 0;
    }
}
