.breadcrumb-wrap {
    text-align: left;
    margin-left: 2.5%;
}

    .breadcrumb-wrap .breadcrumb a,
    .breadcrumb-wrap .breadcrumb span {
        padding: 0 3px 0 3px;
    }

        .breadcrumb-wrap .breadcrumb a::after,
        .breadcrumb-wrap .breadcrumb span::after {
            content: "/";
            padding: 0 0 0 4px;
        }

    .breadcrumb-wrap .breadcrumb {
        background-color: transparent !important;
        margin-bottom: 0;
        padding: 0;
        font-size: 10px;
    }


@media only screen and (max-width: 1200px) {
    .breadcrumb-wrap {
        width: 75%;
    }
}

@media only screen and (max-width: 1000px) {
    .breadcrumb-wrap {
        width: 95%;
    }
}
