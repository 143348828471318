.login-container {
    text-align: center;
    width: 50%;
    margin: 5% auto;
}

    .login-container .input-container {
        margin-bottom: 1%;
    }

        .login-container .input-container i {
            margin-right: 0.5%;
        }

    .login-container .error-message {
        color: red;
    }
