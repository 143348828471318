.social-icons {
    text-align: center;
    width: 100%;
    color: white;
    box-sizing: border-box;
}

    .social-icons a i {
        font-size: 35px;
        margin: 0px 5px;
        box-sizing: border-box;
    }

    .social-icons a {
        font-size: 14pt;
        margin: 0px 5px;
        box-sizing: border-box;
    }

    .social-icons.social-icons--navbar {

    }

        .social-icons.social-icons--navbar span {
            display: none;
        }

        .social-icons.social-icons--navbar a {
            font-size: 28px;
        }