.html-editor-columns-container {

}

    .html-editor-columns-container .columns-container {
        display: grid;
        grid-gap: 30px;
        width: 100%;
    }

        .html-editor-columns-container .columns-container .single-column {
            border-radius: 10px;
        }

            .html-editor-columns-container .columns-container .single-column.float-column {
                box-shadow: 0px 0px 15px 0px #888888;
                padding: 25px 50px;
            }

        .html-editor-columns-container .columns-container .single-column img {
            border-radius: 25px;
        }

    .html-editor-columns-container .columns__4_4 {
        grid-template-columns: 4fr;
    }

    .html-editor-columns-container .columns__4_2-2 {
        grid-template-columns: 2fr 2fr;
    }

    .html-editor-columns-container .columns__4_1-3 {
        grid-template-columns: 1fr 3fr;
    }

    .html-editor-columns-container .columns__4_3-1 {
        grid-template-columns: 3fr 1fr;
    }

    .html-editor-columns-container .columns__4_1-1-2 {
        grid-template-columns: 1fr 1fr 2fr;
    }

    .html-editor-columns-container .columns__4_2-1-1 {
        grid-template-columns: 2fr 1fr 1fr;
    }

    .html-editor-columns-container .columns__4_1-2-1 {
        grid-template-columns: 1fr 2fr 1fr;
    }

    .html-editor-columns-container .columns__4_1-1-1-1 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .html-editor-columns-container .columns__3_1-1-1 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .html-editor-columns-container .columns__3_1-2 {
        grid-template-columns: 1fr 2fr;
    }

    .html-editor-columns-container .columns__3_2-1 {
        grid-template-columns: 2fr 1fr;
    }

@media only screen and (max-width: 1400px) {
    .html-editor-columns-container .columns-container {
        grid-gap: 15px;
    }

        .html-editor-columns-container .columns-container .single-column iframe {
            width: 100% !important;
        }

    .html-editor-columns-container .columns__4_4,
    .html-editor-columns-container .columns__4_2-2,
    .html-editor-columns-container .columns__4_1-3,
    .html-editor-columns-container .columns__4_3-1,
    .html-editor-columns-container .columns__4_1-1-2,
    .html-editor-columns-container .columns__4_2-1-1,
    .html-editor-columns-container .columns__4_1-2-1,
    .html-editor-columns-container .columns__3_1-1-1,
    .html-editor-columns-container .columns__3_1-2,
    .html-editor-columns-container .columns__3_2-1 {
        grid-template-columns: 1fr;
    }

    .html-editor-columns-container .columns__4_1-1-1-1 {
        grid-template-columns: 1fr 1fr;
    }

    .html-editor-columns-container .columns-container .single-column img {
        width: 100%;
        height: auto;
    }
}