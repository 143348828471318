.sub-navbar-container {
}

    .sub-navbar-container .direct-link li {
        color: darkslategrey;
        font-size: 22px;
        padding: 10px 7px;
    }

        .sub-navbar-container .direct-link li:hover {
            background-color: transparent;
            color: #025594;
        }

    .sub-navbar-container .dropdown .dropdown-btn {
        padding: 10px 7px;
    }

    .sub-navbar-container .dropdown .dropdown-btn {
        color: darkslategrey;
        font-size: 22px;
    }

        .sub-navbar-container .dropdown .dropdown-btn .right-caret-icon {
            font-size: 22px;
            color: darkslategrey;
            -ms-transform: rotate(0deg); /* IE 9 */
            transform: rotate(0deg);
            transition: 0.5s;
            margin-left: 5px;
        }

    .sub-navbar-container .dropdown:hover .dropdown-btn .right-caret-icon {
        -ms-transform: rotate(90deg); /* IE 9 */
        transform: rotate(90deg);
        transition: 0.3s;
    }

    .sub-navbar-container .dropdown .direct-dropdown-link:hover,
    .sub-navbar-container .dropdown .direct-dropdown-link:focus {
        background-color: transparent;
        border: none;
    }

    .sub-navbar-container .dropdown .direct-dropdown-link a:hover,
    .sub-navbar-container .dropdown .direct-dropdown-link a:focus {
        color: #025594;
    }

    .sub-navbar-container .dropdown .dropdown-btn {
        cursor: default;
    }

    .sub-navbar-container .dropdown .dropdown-content {
        display: none;
        position: absolute;
        background-color: #eaf9ff;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        opacity: 1;
        transition: 0.4s;
    }

    .sub-navbar-container .dropdown:hover .dropdown-content {
        display: block;
        background-color: #eaf9ff;
    }

    .sub-navbar-container .dropdown .dropdown-content a {
        float: none;
        color: black;
        padding: 4px 12px;
        text-decoration: none;
        display: block;
        text-align: left;
        font-family: century-gothic, sans-serif;
        transition: ease-in-out 0.3s;
        font-size: 20px;
    }

        .sub-navbar-container .dropdown .dropdown-content a:hover {
            background-color: #025594;
            transition: 0.4s;
            color: white;
        }

.header-contact-info {
}

    .header-contact-info i {
        padding-left: 0;
    }

    .header-contact-info i,
    .header-contact-info p {
        margin: 0 0 0 0;
        padding-left: 0px;
    }

    .header-contact-info a {
        display: inline-flex;
    }

    .header-contact-info i,
    .header-contact-info p {
        display: block;
        justify-self: center;
        align-self: center;
        color: #003219;
        font-size: 16px;
        box-sizing: border-box;
    }

.mobile-nav-button {
    display: block;
    position: relative;
    font-size: 30px;
    color: #003219;
}

.mobile-nav-button,
.mobile-nav {
    display: none;
}

.main-nav-wrap {
    width: 100%;
    display: inline-block;
    padding-bottom: 5%;
    box-shadow: 0px 1px 10px grey;
    background-color: white;
    color: white;
    display: grid;
    grid-template-columns: 1.5fr 4fr 1.5fr;
    padding: 0;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    box-shadow: none;
    border-bottom: 5px solid #025594;
}

    .main-nav-wrap .menu-items .dropdown-btn {
        padding-top: 14px;
        font-weight: 500;
        background-color: transparent;
    }

        .main-nav-wrap .menu-items .dropdown-btn:focus {
            border: none;
            outline: none;
        }

    .main-nav-wrap .social-contact .header-contact-info {
        vertical-align: super;
        padding-top: 5px;
    }

        .main-nav-wrap .social-contact .header-contact-info a,
        .main-nav-wrap .social-contact .header-contact-info p,
        .main-nav-wrap .social-contact .header-contact-info i {
            color: white;
            font-size: 22px;
        }

        .main-nav-wrap .social-contact .header-contact-info .fa-phone {
            color: rgb(218, 33, 39);
        }

    .main-nav-wrap .header-contact-info {
        display: inline-flex;
        position: relative;
        text-align: center;
        top: auto;
        right: auto;
    }

    .main-nav-wrap .login-link {
        display: inline-flex;
        margin: 0 15px;
        margin-top: 5px;
        font-size: 28px;
    }

    .main-nav-wrap .main-logo-wrapper {
        justify-content: unset;
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        align-self: center;
        justify-self: center;
        /*grid-row: 1 / 3;*/
    }

    .main-nav-wrap .main-logo {
        min-width: 100px;
        margin: 0 auto;
        position: relative;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 25px;
        /*margin: 10px 0 0 0;*/
        text-align: center;
        padding: 0;
        width: 220px;
    }

    .main-nav-wrap .main-logo__home {
        min-width: 100px;
        margin: 0 auto;
        position: relative;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 25px;
        /*margin: 10px 0 0 0;*/
        text-align: center;
        padding: 0;
        width: 220px;
    }

    .main-nav-wrap .main-nav {
        text-align: right;
        width: 100%;
        text-align: right;
        display: flex;
        color: darkslategrey !important;
        font-size: 22px !important;
    }

        .main-nav-wrap .main-nav ul {
            display: inline-flex;
        }

        .main-nav-wrap .main-nav li {
            list-style: none;
            cursor: pointer;
            transition: ease-in-out 0.3s;
            text-transform: none;
            color: darkslategrey;
            padding: 10px 12px;
            list-style: none;
            padding: 10px 7px;
            cursor: pointer;
            transition: ease-in-out 0.3s;
            color: darkslategrey;
            font-size: 22px;
        }

            .main-nav-wrap .main-nav li.active {
                font-weight: 700;
                color: #f9a73e;
            }

            .main-nav-wrap .main-nav li:hover {
                background-color: transparent;
                color: #0056b3;
            }

        .main-nav-wrap .main-nav .header-contact-info {
            position: relative;
        }

    .main-nav-wrap .nav-items {
        margin-right: 10%;
        justify-self: center;
        align-self: center;
        flex: 1;
        margin-right: auto;
    }

    .main-nav-wrap .social-contact {
        display: inline-block;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        font-size: 22px;
        text-align: center;
    }

        .main-nav-wrap .social-contact .social-icons {
            margin-left: 10px;
            color: darkslategrey;
            padding: 10px;
            box-sizing: border-box;
        }

    .main-nav-wrap .company-name {
        width: 100%;
        text-align: center;
        margin: 0 0 20px 0;
        display: none;
    }

    .main-nav-wrap .site-name {
        margin: 0;
        padding-bottom: 0px;
        padding-top: 0px;
        padding-left: 210px;
    }

    .main-nav-wrap .nav-extras {
        text-align: right;
        padding-top: 3px;
    }

        .main-nav-wrap .nav-extras .nav-extras-main {
            display: inline-flex;
        }

            .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container {
                display: inline-block;
            }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td div,
                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #___gcse_0 form table tbody tr td div table {
                    height: 100% !important;
                }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container #gsc-i-id1 {
                    /*background-image: url("../images/ClientStatic/SearchPlaceholder.PNG") !important;*/
                }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsib_a {
                    padding: 18px 2px;
                    width: 500px;
                }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-input {
                    padding-right: 0;
                }

                .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-search-button.gsc-search-button-v2 {
                    background-color: #819dcf;
                    color: white;
                    font-size: 20px;
                    border-color: transparent;
                    height: 100%;
                    padding: 6px 12px;
                }

                    .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container .gsc-search-button.gsc-search-button-v2 svg {
                        block-size: 50px;
                        inline-size: 50px;
                    }

            .main-nav-wrap .nav-extras .nav-extras-main input {
                font-size: 20px;
                padding: 18px;
                width: 38%;
            }

            .main-nav-wrap .nav-extras .nav-extras-main .search-button {
                background-color: #819dcf;
                color: white;
                font-size: 20px;
                width: 6%;
            }

        .main-nav-wrap .nav-extras .nav-extras-sub {
            margin-top: 10px;
            margin-bottom: 10px;
        }

            .main-nav-wrap .nav-extras .nav-extras-sub span {
                /*margin-right: 10px;*/
            }

            .main-nav-wrap .nav-extras .nav-extras-sub .phone {
                /*background-color: #7298bc;*/
                padding: 8px;
                padding-bottom: 6px;
                border-radius: 5px;
                /*margin-right: 10px;*/
            }

                .main-nav-wrap .nav-extras .nav-extras-sub .phone i,
                .main-nav-wrap .nav-extras .nav-extras-sub .phone p {
                    color: #6b5e4b;
                    font-size: 22px;
                    font-weight: 600;
                }

                .main-nav-wrap .nav-extras .nav-extras-sub .phone i {
                    margin-right: 10px;
                }

                .main-nav-wrap .nav-extras .nav-extras-sub .phone a {
                    text-decoration: none;
                }

            .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container {
                display: inline-block;
                margin-right: 10px;
            }

                .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container .goog-te-gadget-simple {
                    border-radius: 5px;
                    font-size: 20px;
                    padding: 0px;
                    padding-top: 1px;
                }

                    .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container .goog-te-gadget-simple a:hover {
                        text-decoration: none;
                    }

.main-nav-wrap__super {
    margin-bottom: 2%;
}

    /*.main-nav-wrap__super a,
    .main-nav-wrap__super button {
        color: darkslategrey;
    }

        .main-nav-wrap__super a:hover {
            color: #025594;
            text-decoration: none;
        }*/

    .main-nav-wrap__super li {
        text-transform: none;
    }

    .main-nav-wrap__super .sub-nav-wrap {
        grid-column: 1 /span 2;
        height: 10px;
        background-color: #025594;
    }

    .main-nav-wrap__super .nav-extras .nav-extras-main .pay-bill-button {
        background-color: #006f66;
        color: white;
        text-transform: capitalize;
        font-size: 28px;
        font-weight: bold;
        text-decoration: none;
        padding: 15px 25px 13px 25px;
        margin-left: 3px;
        margin-right: 10px;
        text-align: center;
    }

.main-nav ul {
    display: inline-flex;
}

.main-nav li {
    list-style: none;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-transform: none;
    color: #77787c;
    padding: 10px 12px;
}

    .main-nav li.active {
        font-weight: 700;
        color: #f9a73e;
    }

    .main-nav li:hover {
        background-color: white;
        color: #f9a73e;
    }

.main-nav {
    text-align: right;
}

.nav-items {
    margin-right: 10%;
}

.main-logo-wrapper {
    justify-content: unset;
}

.main-nav-wrap {
    display: grid;
    grid-template-columns: 2fr 5fr;
    padding: 0;
    padding-bottom: 5px;
    height: 225px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

    .main-nav-wrap .header-contact-info {
        display: inline-flex;
        position: relative;
        text-align: center;
        top: auto;
        right: auto;
    }

.main-nav-wrap__super .main-nav-wrap,
.main-nav-wrap__super .sub-nav-wrap,
.main-nav-wrap__super .breadcrumb-wrap,
.main-nav-wrap__super #googleSearchResultsContainer {
    padding: 0 10%;
}

.main-nav-wrap__super .mobile-sub-nav-container {
    display: none;
}

.navbar-tab-wrapper {
    /*width: 125%;
    margin-left: -12.5%;*/
    width: auto;
}

    .navbar-tab-wrapper .navbar-tab-row-wrapper {
        background-color: #025594;
    }

.navbar-tab-container {
    width: 80%;
    margin: auto;
}

    .navbar-tab-container .tab-row {
        display: inline-flex;
        width: 100%;
        padding: 0 5px;
    }

        .navbar-tab-container .tab-row .tab {
            color: white;
            font-size: 20px;
            margin: auto 3px 0 3px;
            padding: 3px 0;
            width: 100%;
            text-align: center;
            cursor: pointer;
        }

            .navbar-tab-container .tab-row .tab:hover {
                text-decoration: underline;
            }

            .navbar-tab-container .tab-row .tab.current-tab {
                text-decoration: underline;
                background-color: #6b5e4b;
            }

    .navbar-tab-container .current-tab-contents {
    }

        .navbar-tab-container .current-tab-contents .tab-contents {
        }

            .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper {
                width: 100%;
                padding: 1% 30px;
            }

                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container {
                    display: grid;
                    grid-gap: 30px;
                    width: 100%;
                }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_1-1-1-1 {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_2-1-1 {
                        grid-template-columns: 2fr 1fr 1fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_1-1-2 {
                        grid-template-columns: 1fr 1fr 2fr;
                    }

                    .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_2-2 {
                        grid-template-columns: 2fr 2fr;
                    }

            .navbar-tab-container .current-tab-contents .tab-contents .footer-container {
                color: white;
                background-color: #6b5e4b;
                padding: 3%;
            }

            .navbar-tab-container .current-tab-contents .tab-contents button {
                color: white;
                background-color: #e16541;
                border-radius: 3px;
            }

            .navbar-tab-container .current-tab-contents .tab-contents hr {
                color: #e16541;
                background-color: #e16541;
                border: none;
                height: 6px;
                margin-right: 65%;
            }

            .navbar-tab-container .current-tab-contents .tab-contents p {
                margin-bottom: 0;
            }

@media only screen and (max-width: 1400px) {
    .main-nav-wrap {
        grid-template-columns: 1fr;
        box-sizing: border-box;
    }

        .main-nav-wrap .social-contact {
            display: none;
        }

        .main-nav-wrap .main-logo-wrapper {
            grid-column: span 2;
        }

        .main-nav-wrap .main-logo {
            /*margin: 0 0 20px 25px;*/
        }

        .main-nav-wrap .main-nav ul {
            display: none;
        }

        .main-nav-wrap .mobile-nav {
            display: block;
            z-index: 200;
            position: fixed;
            width: 60%;
            transform: translateX(-100%);
            transition: ease-in 0.3s;
            height: 100%;
            top: 0;
            left: 0;
        }

            .main-nav-wrap .mobile-nav ul {
                display: grid;
                position: fixed;
                background: #025594;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                box-shadow: 2px 0 10px rgba(0,0,0,0.4);
            }

                .main-nav-wrap .mobile-nav ul li {
                    color: white;
                }

        .main-nav-wrap .open.mobile-nav {
            transform: translateX(0);
        }

    .main-nav-wrap {
        grid-template-columns: 1fr;
        box-sizing: border-box;
        height: auto;
    }

        .main-nav-wrap .social-contact {
            display: none;
        }

        .main-nav-wrap .nav-extras .nav-extras-main .pay-bill-button {
            font-size: 20px;
            padding: 18px 15px 15px 15px;
        }

        .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container {
            display: none;
        }

    .mobile-nav-button {
        display: block;
        text-align: center;
        font-size: 36px;
    }
}

@media only screen and (max-width: 1000px) {
    .mobile-nav ul {
        font-size: 18px;
    }

    .mobile-nav .toggle-icon {
        font-size: 30px !important;
    }

    .sub-nav {
        display: none;
    }

    .main-nav-wrap {
        padding: 5px 0 !important;
        grid-template-columns: 1fr;
    }

        .main-nav-wrap .mobile-nav-button {
            display: block;
            position: relative;
            font-size: 30px;
            color: #006f66;
            padding: 10px 50%;
            padding: 0;
            margin: auto;
        }

        .main-nav-wrap .nav-extras .nav-extras-sub .google-translate-container {
            display: none;
        }

        .main-nav-wrap .nav-extras .nav-extras-main .google-search-bar-container {
            display: none;
        }

        .main-nav-wrap .nav-extras .nav-extras-sub {
            display: none;
        }

        .main-nav-wrap .nav-extras {
            display: none;
        }

        .main-nav-wrap .nav-content-container {
            grid-row: span 2/ 1;
        }

        .main-nav-wrap .main-logo {
            float: left;
        }

    .main-nav-wrap__super .mobile-sub-nav-container {
        display: block;
        width: 100%;
    }

        .main-nav-wrap__super .mobile-sub-nav-container .pay-bill-button--mobile-wrapper {
        }

            .main-nav-wrap__super .mobile-sub-nav-container .pay-bill-button--mobile-wrapper .pay-bill-button--mobile {
                background-color: #006f66;
                color: white;
                text-transform: capitalize;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                padding: 10px;
                text-align: center;
                display: block;
                width: 100%;
            }

    .main-nav {
        display: none;
    }

    .main-logo-wrapper {
        width: 80%;
        grid-column: span 1/ 2;
    }

    .navbar-tab-wrapper {
        width: 100%;
        margin: auto;
    }

    .navbar-tab-container {
        width: 100%;
    }

        .navbar-tab-container .tab-row {
            display: block;
            padding: 0px 7px 0px 1px;
        }

            .navbar-tab-container .tab-row .tab.current-tab {
                text-decoration: underline;
                background-color: #5b96c8;
            }

        .navbar-tab-container .current-tab-contents {
            width: 80%;
            margin: auto;
        }

            .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper {
                padding: 2%;
            }

                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_1-1-1-1,
                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_2-1-1,
                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_1-1-2,
                .navbar-tab-container .current-tab-contents .tab-contents .columns-wrapper .columns-container.columns__4_2-2 {
                    grid-template-columns: 1fr;
                }
}

@media only screen and (max-width: 600px) {
    .main-nav-wrap .mobile-nav ul {
        font-size: 18px;
    }

    .main-nav-wrap .mobile-nav .toggle-icon {
        font-size: 30px !important;
    }
}
