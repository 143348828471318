.banner-container {
    width: 100%;
    max-width: 100%;
    height: 300px;
}

    .banner-container .wrap img {
        max-width: 100%;
    }

    .banner-container button {
        color: white;
        background-color: #025594;
        border-radius: 0px;
        padding: 15px;
        font-family: open-sans, sans-serif;
        width: 100%;
        margin: 0 auto;
        text-transform: uppercase;
        transition: ease-in-out 0.3s;
        cursor: pointer;
        font-size: 24px;
    }

        .banner-container button:hover {
            background-color: #025594;
        }

    .banner-container .react-slideshow-container .default-nav {
        padding: 0;
    }

    .banner-container .alice-carousel__stage {
        width: auto;
    }

    .banner-container.banner-medium {
        height: 600px;
    }

    .banner-container .action-area {
        padding: 20px;
        color: white;
        text-align: center;
        justify-self: center;
        align-self: end;
        width: 20%;
        margin: auto;
        top: -270px;
        position: relative;
        font-size: 78px;
        width: auto;
        font-weight: bold;
    }

    .banner-container .button-area {
        margin: auto;
        position: relative;
        width: fit-content;
    }

        .banner-container .button-area.button-upper-left {
            top: -780px;
            left: -43%;
        }

        .banner-container .button-area.button-upper-right {
            top: -780px;
            left: 43%;
        }

        .banner-container .button-area.button-bottom-left {
            top: -380px;
            left: -43%;
        }

        .banner-container .button-area.button-bottom-right {
            top: -380px;
            left: 43%;
        }

        .banner-container .button-area.button-bottom-middle {
            top: -380px;
        }

        .banner-container .button-area.button-middle {
            top: -580px;
        }

    .banner-container .banner-bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 300px;
        display: grid;
    }

        .banner-container .banner-bg img {
            margin: 0 auto;
            width: 100%;
            max-height: 900px;
        }

    .banner-container.banner-medium .banner-bg img {
        max-height: 600px;
    }

    .banner-container.banner-medium .action-area {
        top: -390px;
        text-align: left;
    }

    .banner-container.banner-medium .sub-action-text {
        margin: auto;
        top: -440px;
        position: relative;
        font-size: 34px;
        width: auto;
        padding: 20px;
        color: white;
        text-align: left;
        justify-self: center;
        align-self: end;
    }

    .banner-container.banner-large {
        height: 900px;
    }

    .banner-container.banner-small .banner-bg img {
        height: 300px;
    }

    .banner-container.banner-large .banner-bg img {
        height: 900px;
    }

    .banner-container.banner-large .action-area {
        top: -590px;
        font-weight: normal;
        text-align: left;
    }

    .banner-container.banner-large .sub-action-text {
        margin: auto;
        top: -645px;
        position: relative;
        font-size: 34px;
        width: auto;
        padding: 20px;
        color: white;
        text-align: left;
        justify-self: center;
        align-self: end;
    }

    .banner-container.banner-large .inside-banner-container,
    .banner-container.banner-medium .inside-banner-container {
        width: 80%;
        margin: auto;
    }

    .banner-container.banner-large button {
        width: 100%;
    }

    .banner-container.banner-large .button-area {
        top: -640px;
        text-align: left;
        margin: unset;
    }

        .banner-container.banner-large .button-area button {
            text-align: left;
            font-weight: normal;
        }

        .banner-container.banner-large .button-area a {
            display: inline-block;
            padding-left: 20px;
        }

@media only screen and (max-width: 1200px) {
    .banner-container .action-area {
        width: 80%;
        display: none;
    }

    .banner-container .sub-action-text {
        display: none;
    }

    .banner-container .button-area {
        display: none;
    }
}
