.form-builder-single-submission-container .page-title {
    font-weight: bold;
}

.form-builder-single-submission-container .grid-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.form-builder-single-submission-container .dual-grid--offset {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 5px;
    margin: 0 auto;
    width: 90%;
}

    .form-builder-single-submission-container .dual-grid--offset label {
        font-weight: bold;
        text-align: left;
    }

.form-builder-single-submission-container .back-btn {
    font-size: xx-large;
}