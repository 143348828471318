.form-component-container {
}

    .form-component-container .grid-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .form-component-container .dual-grid-offset {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 5px;
        max-width: 90%;
        margin: 0 auto;
        width: 100%;
    }

    .form-component-container .form-title {
        text-align: center;
    }

    .form-component-container .form-submit {
        text-align: center;
        margin-top: 5px;
    }

        .form-component-container .form-submit .form-button {
            background-color: #025594 !important;
        }

    .form-component-container input,
    .form-component-container select {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

    .form-component-container .checkbox-wrapper {
        margin-right: 91%;
    }

.image-form-container .dual-grid-offset label {
    display: block !important;
    margin-bottom: auto;
}
