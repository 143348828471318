.image-form-container {
    width: 95%;
    margin: auto;
    margin-bottom: 2%;
    box-shadow: 0px 0px 20px grey;
}

    .image-form-container .lightweight {
        font-weight: 300;
    }

        .image-form-container .lightweight .text-right .tsdark {
            color: grey;
            font-size: 48px;
        }

    .image-form-container .smallfont {
        font-size: 16px;
    }

    .image-form-container.getstarted2 img {
        width: 95%;
    }

    .image-form-container.getstarted2 hr {
        border: none;
        padding: 1px;
        background: #025594;
    }

    .image-form-container.getstarted2 .image-full-bg {
        background-position: center;
        background-size: cover;
        height: auto;
        width: 100%;
        text-align: center;
    }

    .image-form-container .contact-input-wrapper {
        padding: 30px;
        color: grey;
    }

        .image-form-container .contact-input-wrapper h1 {
            color: grey;
        }

    .image-form-container .image-full-bg {
        /*margin-left: 30%;*/
    }

        .image-form-container .image-full-bg img {
            margin: auto;
            /*width: 50%;*/
        }

    .image-form-container .text-right,
    .image-form-container .text-center,
    .image-form-container .form-title {
        /*display: none;*/
    }

    .image-form-container.grid-half {
        grid-template-columns: 1fr 2fr;
        width: 95%;
    }

    .image-form-container .form-component-container .form-submit {
        text-align: right;
        margin-right: 5%;
    }

    .image-form-container .form-component-container .form-button {
        background-color: rgb(218, 33, 39);
        color: white;
        font-size: 20px;
    }

    .image-form-container .dual-grid-offset {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

        .image-form-container .dual-grid-offset label {
            display: none;
        }

        .image-form-container .dual-grid-offset input,
        .image-form-container .dual-grid-offset select,
        .image-form-container .dual-grid-offset textarea {
            border: 2px darkslategrey solid;
        }

@media only screen and (max-width: 1400px) {
    .image-form-container .image-full-bg img {
        width: 100%;
        display: none;
    }

    .image-form-container.grid-half {
        grid-template-columns: 1fr;
        width: 95%;
    }
}
