.component-form .grid-wrapper {
    width: 100%;
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
}

.component-form .dual-grid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 5px;
    max-width: 90%;
    margin: 0 auto;
}

.component-form .dual-grid__1 {
    grid-column: 1;
}

.component-form .dual-grid__1-2 {
    grid-column: 1 /span 2;
}

.component-form .grid-span-2 {
    grid-column: span 2;
}

.component-form .grid-span-3 {
    grid-column: span 3;
}

.component-form .grid-span-4 {
    grid-column: span 4;
}

.component-form .tri-grid {
    display: grid;
    grid-template-columns: 1fr 4fr 0.5fr;
    grid-gap: 5px;
    max-width: 90%;
    margin: 0 auto;
}

.component-form .tri-grid__1-3 {
    grid-column: 1 /span 3;
}

.component-form .inner-tri-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    margin: 0 auto;
}

.component-form .inner-quad-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    margin: 0 auto;
}

.component-form .inner-hex-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    margin: 0 auto;
}

.component-form .inner-octo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    margin: 0 auto;
}

.component-form .side-bar-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 5px;
    margin: 0 auto;
}

.component-form .form-title {
    text-align: center;
}

.component-form button {
    background-color: #3c8dbc;
    border-color: #367fa9;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: white;
    padding: 12px 6px;
    font-weight: normal;
    font-size: 16px;
    /*margin: 0 0 10px 10px;*/
    min-width: 50px;
}

    .component-form button:hover,
    .component-form button:focus {
        background-color: #367fa9;
        border-color: #204d74;
        text-decoration: none;
        outline: none;
    }

    .component-form button:active {
        background-color: #204d74;
        border-color: #122b40;
        box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    }

    .component-form button:disabled {
        background-color: rgba(60, 141, 188, 0.5);
        cursor: default;
        pointer-events: none;
    }

    .component-form button.delete-btn {
        background-color: #dd4b39;
        border-color: #da3a25;
        color: white;
        margin-left: 10px;
    }

        .component-form button.delete-btn:hover,
        .component-form button.delete-btn:focus {
            background-color: #da3a25;
            border-color: #99291a;
        }

        .component-form button.delete-btn:active {
            background-color: #99291a;
            border-color: #57170f;
        }

        .component-form button.delete-btn:disabled {
            background-color: rgba(221, 75, 57, 0.5);
        }

.component-form .side-menu {
    margin: 5% 0;
    padding-right: 5%;
    border-right: 2px solid #888;
}

    .component-form .side-menu .side-menu-button {
        font-weight: 600;
        cursor: pointer;
        padding: 10px;
    }

        .component-form .side-menu .side-menu-button:hover {
            background-color: #bbb;
        }

    .component-form .side-menu .sub-component-button {
        font-weight: 600;
        padding-right: 5px;
        border: none;
        display: inline;
    }

        .component-form .side-menu .sub-component-button.disable-dnd {
            background-color: #6894b1;
            cursor: default;
            padding: 10px;
            display: block;
            text-align: left;
        }

    .component-form .side-menu .current-component__drag-icon {
        margin-top: 2%;
        float: left;
    }

    .component-form .side-menu .current-component__edit {
        padding-left: 5px;
        padding-right: 5px;
    }

        .component-form .side-menu .current-component__edit:hover {
            cursor: pointer;
            color: #80BA54;
        }

    .component-form .side-menu .current-component__delete {
        padding-left: 5px;
        padding-right: 5px;
    }

        .component-form .side-menu .current-component__delete:hover {
            cursor: pointer;
            color: red;
        }

    .component-form .side-menu .current-component__view {
        padding-left: 5px;
        padding-right: 5px;
    }

        .component-form .side-menu .current-component__view:hover {
            cursor: pointer;
            color: #007bff;
        }

    .component-form .side-menu .current-component__manage {
        float: right;
        display: inline;
    }

.component-form .component-form-popup-container .popup-cancel-btn {
    float: right;
    color: #dd4b39;
    background: transparent;
    border: transparent;
    font-size: x-large;
    box-shadow: none;
    padding: 9px 0;
    pointer-events: all !important;
}

    .component-form .component-form-popup-container .popup-cancel-btn:hover,
    .component-form .component-form-popup-container .popup-cancel-btn:focus {
        color: #da3a25;
    }

    .component-form .component-form-popup-container .popup-cancel-btn:active {
        color: #99291a;
    }

    .component-form select,
    .component-form input {
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        height: 50px;
        color: rgba(46, 46, 46, .8);
        border: 1px solid rgb(225, 225, 225);
        outline: none;
        margin: 0 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        resize: none;
    }

.component-form .page-order-container {
    margin: auto 25%;
}

    .component-form .page-order-container .comment-text {
        font-size: 14px;
    }

.component-form label {
    margin-top: 12px;
    text-align: left;
    padding-left: 0;
    font-weight: bold;
}

.component-form textarea {
    min-height: unset;
    height: unset;
    resize: vertical;
}

.component-form .info-icon {
    font-size: large;
    margin-top: 15px;
    margin-left: 5px;
}

    .component-form .info-icon:hover {
        color: #80ba54;
    }

.component-form .disable-wrapper {
    pointer-events: none !important;
}

.component-form.disable-form button,
.component-form.disable-form select,
.component-form.disable-form input,
.component-form.disable-form textarea {
    pointer-events: none !important;
}

    .component-form.disable-form select,
    .component-form.disable-form input,
    .component-form.disable-form textarea {
        background-color: rgb(247, 247, 247);
    }

    .component-form.disable-form button.delete-btn {
        background-color: rgba(221, 75, 57, 0.5);
    }

    .component-form.disable-form button {
        border-color: #122b40;
        background-color: rgba(60, 141, 188, 0.5);
    }

.component-form .editor-wrapper.disable-wrapper .tox-tbtn {
    background-color: rgb(247, 247, 247);
}

.component-form .component-form-popup-wrapper {
    /*display: none;*/
    position: fixed;
    z-index: 3;
    padding-top: 3%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.component-form .component-form-popup-container .popup-dual-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    max-width: 90%;
    margin: 0 auto;
    background-color: rgb(250, 250, 250);
    padding-bottom: 10px;
}

    .component-form .component-form-popup-container .popup-dual-grid__1-2 {
        grid-column: 1 /span 2;
    }

.component-form .checkbox-wrapper {
    margin-right: 95%;
}
