/* This is where styles that do not relate to a component exist. */

@import url("https://use.typekit.net/lzq6ner.css");

body {
    font-family: sans-serif !important;
}

ul {
    display: block;
}

.full-width-wrapper {
    /*width: 125%;
    margin-left: -12.5%;*/
}

.component-wrapper {
    width: 65%;
    margin: auto;
}

.page-name-header {
    text-align: center;
}

.lightweight {
    font-weight: 300;
}

.midweight {
    font-weight: 400;
}

.heavyweight {
    font-weight: 700;
}

.after-header {
    margin: 100px 0;
}

    .after-header h1,
    .after-header h1,
    .after-header h3,
    .after-header h4,
    .after-header h5,
    .after-header h6 {
        padding: 5px;
        margin: 0;
    }

.lightweight .text-right .tsdark {
    color: grey;
    font-size: 48px;
}

.feature-hr {
    background-color: #f9a73e;
    padding: 1px;
    border: 0;
    width: 20%;
    margin: 0 auto 15px auto;
}

.submit-button {
    color: white;
    background: #adbd37;
    border: none;
    padding: 10px 20px;
    float: right;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
textarea {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    height: 50px;
    color: rgba(46, 46, 46, .8);
    border: 1px solid rgb(225, 225, 225);
    outline: none;
    margin: 0 0 0 0;
    padding-left: 15px;
    padding-right: 15px;
    resize: none;
}

textarea {
    min-height: 200px;
}

.contact-inputs {
    grid-gap: 10px;
}

.comment-wrap {
    margin-top: 10px;
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

ul {
    display: inline-flex;
}

li {
    list-style: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-transform: uppercase;
}

    li.page-item {
        padding: 0;
    }

        li.page-item:hover {
            background-color: transparent;
        }

.logo {
    width: 400px;
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

a {
    text-decoration: none;
    color: inherit;
}

h6 {
    position: relative;
    float: left;
    padding: 5px 0px !important;
    margin: 0;
}

.dynamic-pages-container .main-nav-wrap .site-name {
    color: darkslategrey;
    font-size: 22px;
}

@media only screen and (max-width: 1400px) {
    .dynamic-pages-container {
        width: auto;
    }

    .component-wrapper {
        width: 90%;
    }
}

@media only screen and (max-width: 1200px) {
    ul {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .dynamic-pages-container {
        width: auto;
    }
}
