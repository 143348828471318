.announcement-container {
    
}

    .announcement-container .home-announce {
        display: grid;
        grid-template-columns: 2fr 10fr 1fr;
        text-align: center;
        color: white;
        background: #025594;
        font-size: 20px;
        font-weight: 600;
        padding: 14px 1px;
        width: 100%;
    }

        .announcement-container .home-announce.hide-announcement {
            display: none;
        }

    .announcement-container p {
        padding: 5px;
        margin: 0;
    }

    .announcement-container .announcement-remove {
        cursor: pointer;
    }

@media only screen and (max-width: 1400px) {
    .announcement-container .home-announce {
        grid-template-columns: 2fr 10fr 1fr;
    }

        .announcement-container .home-announce .announcement-title {
            font-size: 26px;
        }

        .announcement-container .home-announce .announcement-remove {
            font-size: 26px;
        }

        .announcement-container .home-announce .announcement-content {
            text-align: center;
            font-size: 20px;
            padding: 5px 5px 0 5px;
        }
}

@media only screen and (max-width: 1000px) {
    .announcement-container .home-announce {
        padding: 0;
        margin: 0;
        grid-template-columns: 0fr 6fr 0.5fr;
    }

        .announcement-container .home-announce .announcement-title {
            /*background-image: -webkit-linear-gradient( 165deg, #5397d2 35%, #095587 35%);*/
        }

        .announcement-container .home-announce .announcement-remove {
        }

        .announcement-container .home-announce .announcement-content {
            text-align: left;
            font-size: 14px;
            padding: 5px 5px 0 5px;
        }
}