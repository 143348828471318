.tags-and-chips-container .tag-wrapper {
    text-align: left;
}

.tags-and-chips-container .tag-wrapper .tag {
    display: inline-block;
    background-color: rgb(69, 108, 134);
    color: white;
    padding: 1px 10px;
    border-radius: 15px;
    margin-top: 5px;
    margin-right: 5px;
}

    .tags-and-chips-container .tag-wrapper .tag .remove-tag {
        color: lightgrey;
    }

        .tags-and-chips-container .tag-wrapper .tag .remove-tag:hover {
            color: white;
            cursor: pointer;
        }

.tags-and-chips-container .tag-grid-wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-gap: 5px;
    margin-top: 5px;
}

    .tags-and-chips-container .tag-grid-wrapper .grid-tag {
        background-color: rgba(69, 108, 134, 0.5);
        color: white;
        text-align: center;
    }

        .tags-and-chips-container .tag-grid-wrapper .grid-tag .tag-name {
            font-size: large;
        }

        .tags-and-chips-container .tag-grid-wrapper .grid-tag .grid-tag-content {
            width: auto;
            height: 100%;
        }

        .tags-and-chips-container .tag-grid-wrapper .grid-tag .edit-tag-btns {
            display: inline-flex;
        }