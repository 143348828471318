.form-builder-submissions-container .page-title {
    text-align: center;
}

.form-builder-submissions-container .view-btn:hover {
    cursor: pointer;
    color: #007bff;
}

.form-builder-submissions-container .delete-btn:hover {
    cursor: pointer;
    color: red;
}
